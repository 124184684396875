import mutations from './mutations';
import { compareAsc } from '@/store/store';

export const getExpertReportSettings = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('Report/GetExpertReportSettings', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const setReports = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('Report/AddExpertReportSetting', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getGuide = (http, tableName, flagGlobalSetValue) => {
	let params = {
		filters: [],
		sortOption: {
			fieldName: '',
			sortType: null
		},
		pageSize: 999,
		currentPage: 0,
		useANDoperator: true,
		columns: []
	};
	return new Promise((resolve, reject) => {
		http.post(`https://apiosnok.minzdrav.gov.ru/LookUps/GetLookUps?tableName=${tableName}`, params)
			.then((result) => {
				result.data.data.sort((a, b) => compareAsc(a.lookupName, b.lookupName));

				if (flagGlobalSetValue) mutations.addGuide(tableName, result.data.data);
				resolve(result);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getRegions = (http) => {
	return new Promise((resolve, reject) => {
		http.get('MedicalOrgs/Regions')
			.then((result) => {
				result.data.sort((a, b) => compareAsc(a.Name, b.Name));
				resolve(result);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getMedicalOrgs = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('MedicalOrgs/GetMedicalOrgs', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const deleteReport = (http, reportId) => {
	return new Promise((resolve, reject) => {
		http.post(`Report/DeleteExpertReportSetting?reportId=${reportId}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getResultReportByIdExcel = (http, reportId) => {
	return new Promise((resolve, reject) => {
		http.get(`Report/GetResultReportByIdExcel?reportId=${reportId}`, {
			responseType: 'blob'
		})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
