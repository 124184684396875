<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12">
		<div class="mb-12">
			<h1 class="flex w-full text-xl h-6 mb-6 sm:mb-7 font-extrabold sm:text-2xl">Универсальный отчет по МО</h1>
			<Tabs @click="setSelectedTab">
				<TabItem title="Пользовательский режим" :isActive="true">
					<UserMode />
				</TabItem>
				<TabItem title="Экспертный режим" :isActive="false">
					<ExpertMode />
				</TabItem>
				<TabItem title="Сохраненные отчеты" :isActive="false">
					<SavedReports />
				</TabItem>
			</Tabs>
		</div>
		<div v-if="selectedTab !== 2" class="h-auto filter mb-5">
			<div class="w-full flex flex-wrap items-center justify-between gap-5">
				<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Отчет</h1>
				<div class="flex items-center justify-end gap-5">
					<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcel()" :disabled="loadToExcell" :load="loadToExcell" />
				</div>
			</div>
		</div>
		<div v-if="selectedTab !== 2" class="flex flex-col relative">
			<Table :fields="fields" :data="dataTable.data" :loadingTable="loadingTable" :notFiltered="false" />
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<Pagination :page="dataTable.page" :totalCount="dataTable.totalCount" :params="getParams" @query="getData" />
			</div>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
	</div>
</template>

<script>
import UserMode from './UserMode.vue';
import ExpertMode from './ExpertMode.vue';
import SavedReports from './SavedReports.vue';
import Tabs from '@/components/Tabs.vue';
import TabItem from '@/components/TabItem.vue';
import Button from '@/components/Button.vue';
import Table from '@/components/Table.vue';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import { actions } from '@/store/store';
import getters from '../store/getters';
import { getRegions, getMedicalOrgs, getResultReportByIdExcel } from '../store/actions';
import mutations from '../store/mutations';

export default {
	name: 'Main',
	components: {
		UserMode,
		ExpertMode,
		SavedReports,
		Tabs,
		TabItem,
		Button,
		Table,
		loadingComponent,
		Pagination
	},
	computed: {
		loadingTable: getters.loadingTable,
		dataTable: getters.getDataTable,
		getParams: getters.getParams,
		getFlagDownloadExcel: getters.getFlagDownloadExcel,
		getIdForExcel: getters.getIdForExcel
	},
	data() {
		return {
			selectedTab: null,
			loadToExcell: false,
			totalCount: 0,
			page: 0,
			fields: [
				{
					Name: 'Субъект РФ',
					fieldName: 'regionName',
					width: '33%',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Медицинская организация',
					fieldName: 'name',
					width: '67%',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			]
		};
	},
	methods: {
		...actions,
		loadTable: mutations.loadTable,
		setYears: mutations.setYears,
		setRegions: mutations.setRegions,
		changeDataTable: mutations.changeDataTable,
		setSelectedTab(index) {
			this.selectedTab = index;
		},
		downloadExcel() {
			if (this.getFlagDownloadExcel) {
				this.showNotification('Для экспорта результатов отчета необходимо сохранить отчет. Введите наименование отчета в соответствующее поле и нажмите на кнопку "Сохранить".', 'error');

				return;
			}

			this.loadToExcell = true;
			getResultReportByIdExcel(this.$http, this.getIdForExcel)
				.then((res) => {
					let fileNameAndType = decodeURI(res.headers['content-disposition'].split('filename*=')[1].split("''")[1]);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.showNotification('Ошибка при выгрузке отчета, обратитесь к Администратору!', 'error');
						this.loadToExcell = false;
					}
				});
		},
		getData(params) {
			this.loadTable(true);

			getMedicalOrgs(this.$http, params).then((res) => {
				this.changeDataTable(res.data);

				this.loadTable(false);
			});
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		}
	},
	created() {
		this.getSystemYearData().then((result) => {
			let yearOptions = result.data.availableYears.map((x) => {
				return { name: x.toString(), checked: false };
			});

			this.setYears(yearOptions);
		});

		getRegions(this.$http).then((res) => {
			let response = [];
			res.data.forEach((el) => {
				response.push({
					id: el.Id,
					name: el.Name,
					checked: false
				});
			});

			this.setRegions(response);
		});
	}
};
</script>
